import Inputmask from 'inputmask';

export default function inputMaskInit(context) {
    context = typeof context !== 'undefined' ? context : document;

    let phoneInputs = context.querySelectorAll('[data-phone-mask]');

    if (phoneInputs.length) {
        Inputmask({
            mask: '+7 (999) 999-99-99',
            showMaskOnHover: false,
            clearIncomplete: true,
        }).mask(phoneInputs);
    }

    let nameInputs = context.querySelectorAll('[data-name-mask]');

    if (nameInputs.length) {
        Inputmask({
            showMaskOnHover: false,
            regex: '[а-яА-Я \-]*',
        }).mask(nameInputs);
    }
}